<template>
  <body-with-header class="h-100" title="جشنواره و کد تخفیف" :ads="true">
    <CRow class="advice-page position-absolute body-wrapper">
      <CRow v-if="!loading && rowData.length === 0" class="flex-center w-100">
        <no-data :show="!loading && rowData.length === 0" message="هیچ توصیه ای برای زمین یافت نشد." class="emptyTitle"/>
      </CRow>
      <CRow v-else class="w-100">
        <CCol md="3" class="advice-wrapper">
          <vue-element-loading
            :active="loading"
            :text="loadingText"
            spinner="bar-fade-scale"
            color="var(--secondary)"
          />
          <div class="vertical-g-20">
            <image-card
              v-for="(item, index) of rowData"
              :active="selectedRow === item.id"
              :key="index"
              :loading="loadingInfo && selectedRow === item.id"
              :image="getSrc(item.image)"
              @showDetail="showModal(item.id)"
            >
                <h6 class="mb-1">{{ item.name }}</h6>
                <p class="small">{{ item.description }}</p>
            </image-card>
          </div>
        </CCol>

        <CCol col="9" class="main-card planInfoBox" v-if="adviceInfo">

          <div class="w-100 h-100">
            <vue-element-loading
              :active="loadingInfo"
              text="دریافت اطلاعات توصیه"
              color="var(--secondary)"
            />
            <div v-html="adviceInfo" class="adviceInfoBox"></div>
          </div>
        </CCol>
      </CRow>
    </CRow>
  </body-with-header>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../constants/config";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import ImageCard from "../../components/imageCard.vue";
import NoData from "../../components/no-data.vue";

export default {
  name: "adviceList",
  components: {NoData, ImageCard, BodyWithHeader},
  data() {
    return {
      rowData: [],
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      loadingInfo: false,
      selectedRow: 0,
      adviceInfo: "",
      searchList: null,
    };
  },
  watch: {
    searchList: function () {
      this.fetchData();
    },
  },
  props:["adviceId"],
  methods: {
    ...mapActions("advice", ["GetAllWithUserFarms", "GetAdviceInfoById"]),
    getSrc(image) {
      return `${apiUrlRoot}/Advice/${image}`;
    },
    async fetchData() {
      this.loading = true;
      var result = await this.GetAllWithUserFarms({});
      this.loading = false;
      if (result.succeeded) {
        this.rowData = result.data;
        if (result.data.length > 0) {
          this.showModal(this.adviceId ?? result.data[0].id);
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    async showModal(id) {
      this.selectedRow = id;
      this.loadingInfo = true;
      var result = await this.GetAdviceInfoById({id: this.selectedRow});
      console.log("result", result);
      this.loadingInfo = false;
      if (result.succeeded) {
        this.adviceInfo = result.data.adviceInfo;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.image_resized {
  width: 100% !important;
}
</style>
<style scoped>
.advice-page{
  left: 0;
  right: 0;
}

.planInfoBox {
  overflow-y: auto;
}

.adviceInfoBox {
  max-width: 45rem;
  margin: auto;
}

.emptyTitle {
  padding-top: 10%;
  color: #bbb;
  text-align: center;
}

.advice-wrapper {
  height: 80vh;
  overflow: auto;
  top: 9vh;
  position: sticky !important;
}
</style>
