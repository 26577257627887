<script>
export default {
  name: "imageCard",
  props:{
    loading:{
      type:Boolean,
      default:false
    },
    active:{
      type:Boolean,
      default:false
    },
    image:{
      type:String,
    }
  },
  methods:{
    onCardClicked(){
      this.$emit("showDetail");
    }
  }
}
</script>

<template>
  <div class="base-card overflow-hidden card-wrapper cursor-pointer" :class="{'active':active}" @click="onCardClicked">
    <div class="gradient-shadow"></div>
    <vue-element-loading
      :active="loading"
      color="var(--secondary)"
    />
    <font-awesome-icon v-if="active" icon="check" class="position-absolute left-0 top-0 m-3 text-white z-50" />
    <img :src="image" alt=""/>
    <div class="title-advice position-absolute text-white-80">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.card-wrapper{
  width: 100%;
  height: 7rem;
  position: relative;
  transition: all .4s;
}

.card-wrapper img {
  border-radius: var(--border-radius);
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: all .4s;
}

.gradient-shadow {
  background: linear-gradient(310deg, #000, transparent);
  position: absolute;
  inset: 0;
  z-index: 10;
  transition: all .4s;
}

.title-advice {
  bottom: 15px;
  left: 15px;
  right: 15px;
  z-index: 20;
}

.card-wrapper:hover img {
  transform: scale(1.2);
}

.card-wrapper:not(.active) {
  transform: scaleX(.95);
}
</style>
